import React, { FC } from "react";
import { Table as FlowbiteTable, TableProps } from "flowbite-react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

interface CourieTableProps extends TableProps {
  Head?: typeof FlowbiteTable.Head;
  HeadCell?: typeof FlowbiteTable.HeadCell;
  Body?: typeof FlowbiteTable.Body;
  Row?: typeof FlowbiteTable.Row;
  Cell?: typeof FlowbiteTable.Cell;
}

const Table: React.FC<CourieTableProps> & {
  Head: typeof FlowbiteTable.Head;
  HeadCell: typeof FlowbiteTable.HeadCell;
  Body: typeof FlowbiteTable.Body;
  Row: typeof FlowbiteTable.Row;
  Cell: typeof FlowbiteTable.Cell;
  // Footer: typeof FlowbiteTable.Footer;
} = ({ children }) => {
  const { Head, Body } = FlowbiteTable;

  return (
    <>
      <FlowbiteTable style={{ height: "100%" }}>{children}</FlowbiteTable>
      {/* <Pagination /> */}
    </>
  );
};

Table.Head = FlowbiteTable.Head;
Table.HeadCell = FlowbiteTable.HeadCell;
Table.Body = FlowbiteTable.Body;
Table.Row = FlowbiteTable.Row;
Table.Cell = FlowbiteTable.Cell;

export { Table };
