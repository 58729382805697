import React from "react";
import {
  PhoneInput as BasePhoneInput,
  PhoneInputProps,
} from "baseui/phone-input";

const PhoneInput: React.FC<PhoneInputProps> = (props) => {
  return (
    <BasePhoneInput
      {...props}
      overrides={{
        CountrySelect: {
          props: {
            overrides: {
              ControlContainer: {
                style: ({ $theme }: any) => ({
                  //   outline: "rgb(249 250 251)",
                  //   backgroundColor: "rgb(249 250 251)",
                }),
              },
            },
          },
        },
        Root: {
          style: ({ $theme }) => ({
            outline: "rgb(249 250 251)",
            backgroundColor: "rgb(249 250 251)",
          }),
        },
        Input: {
          props: {
            overrides: {
              Input: {
                style: ({ $theme }: any) => ({
                  backgroundColor: "rgb(249 250 251)",
                }),
              },
              InputContainer: {
                style: ({ $theme }: any) => ({
                  backgroundColor: "rgb(249 250 251)",
                }),
              },
            },
          },
        },
        ...props.overrides,
      }}
    />
  );
};

export default PhoneInput;
