/* eslint-disable react-hooks/exhaustive-deps */
/**
 * The Layout component is the top-level component of the dashboard application.
 *
 * It renders the Header, Nav, and SubMenu components, and renders its children
 * as the main content of the page.
 */

import React, { useEffect, useState } from "react";
import { Header } from "@src/common/components";
import { Nav } from "@src/common/components";
import { Sidebar } from "@src/common/components";
import { useRouter } from "next/router";
import Head from "next/head";
import { SUBMENU_ITEM } from "./types";
import { NAV_ITEMS, SUBMENU_ITEMS } from "./utils/navigationConstants.ts";
import { useCourieStore } from "@src/common/lib/store";
import { LayoutToast } from "./LayoutToast";
import { PlusIcon } from "@heroicons/react/24/solid";
import Link from "next/link";
import { useLayout } from "./contexts/LayoutContext";

type Props = {
  children: React.ReactNode;
};

const initailCurrentPageState = SUBMENU_ITEMS[0];

export default function Layout({ children }: Props) {
  return <LoggedInLayout>{children}</LoggedInLayout>;
}

function LoggedInLayout({ children }: Props) {
  const { widgets, toast } = useCourieStore();
  const { badgeCounts } = useLayout();
  const router = useRouter();

  const [currentPage, setCurrentPage] = useState<SUBMENU_ITEM | undefined>(
    initailCurrentPageState
  );
  const [rightPanelVisible, setRightPanelVisible] = useState<boolean>(true);

  useEffect(() => {
    const pathname = router.pathname;
    if (pathname.includes("routingjob")) {
      setCurrentPage(SUBMENU_ITEMS[0]);
      return;
    }
    const currentPage = SUBMENU_ITEMS.find((menuItem: SUBMENU_ITEM) => {
      if (pathname.includes(menuItem.page)) {
        return menuItem;
      }
    });
    setCurrentPage(currentPage);
  }, [router.pathname]);

  useEffect(() => {
    const isWidgetSelected = widgets.some((widget) => widget.selected);
    if (isWidgetSelected) {
      setRightPanelVisible(true);
    } else {
      setRightPanelVisible(false);
    }
  }, [widgets]);

  const getPopoverContent = (navItem: any) => {
    const submenuItem = SUBMENU_ITEMS.find(
      (menuItem: SUBMENU_ITEM) => menuItem.page === navItem.label.toLowerCase()
    );

    if (!submenuItem?.items) return null;

    return (
      <div className="py-2">
        {submenuItem.items.map((item) => (
          <Link href={item.href} key={item.label}>
            <div className="flex items-center px-3 py-2 hover:bg-slate-50 cursor-pointer">
              <div className="w-8 h-8 rounded-full flex items-center justify-center text-slate-600">
                {item.icon}
              </div>
              <span className="ml-3 text-slate-700 text-sm font-medium">
                {item.label}
              </span>
            </div>
          </Link>
        ))}
      </div>
    );
  };

  return (
    <>
      <Head>
        <title>Courie</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <div
        className="dashboard-app-layout bg-slate-50"
        style={{
          gridTemplateColumns: rightPanelVisible
            ? "70px auto 350px"
            : widgets.length > 0
            ? "70px auto 65px"
            : "70px auto 5px",
        }}
      >
        <Header className="app-header">
          <Nav.Group>
            {NAV_ITEMS.map((item: any, i: number) => (
              <Nav.Item
                key={i}
                {...item}
                active={router?.pathname.includes(item.label.toLowerCase())}
                badgeCount={
                  item.label === "Office" ? badgeCounts.customers || 0 : 0
                }
                popoverContent={getPopoverContent(item)}
              />
            ))}
          </Nav.Group>
        </Header>
        <Sidebar.Group className="app-sidebar">
          <div>
            {currentPage?.items &&
              currentPage.items.map((item: any) => {
                if (item.label === "Create Order") {
                  return null;
                }
                return (
                  <div key={item.label} className="mb-3">
                    <Sidebar.Item
                      {...item}
                      active={router?.pathname === item.href}
                      badgeContent={
                        item.label === "Customers"
                          ? badgeCounts.customers || 0
                          : 0
                      }
                    />
                  </div>
                );
              })}
          </div>
          <div className="h-5 mb-16">
            <Link className="mb-3" href="/orders/create">
              <Sidebar.Item
                label="Create"
                tooltipContent="Create New Order"
                active={false}
                icon={<PlusIcon className="w-6 h-6 text-primary-600" />}
              />
            </Link>
          </div>
        </Sidebar.Group>
        <section className="app-content">{children}</section>
      </div>
      {toast && <LayoutToast toast={toast} />}
    </>
  );
}
