import React, { FC, Fragment, useState } from "react";
import { Listbox as HeadlessListbox, Transition } from "@headlessui/react";
import { Tooltip } from "../Tooltip";

interface ListboxProps {
  items: any[];
  initialSelection?: any;
  onSelectionChange: (selectedItem: any) => void;
  children?: React.ReactNode;
}

interface ButtonProps {
  children?: React.ReactNode;
}

interface ListboxOptionsProps {
  children?: React.ReactNode;
}

interface ListboxOptionProps {
  value: any;
  children?: React.ReactNode;
}

const Listbox: FC<ListboxProps> & {
  Button: FC<ButtonProps>;
  Options: FC<ListboxOptionsProps>;
  Option: FC<ListboxOptionProps>;
} = ({ items, initialSelection, onSelectionChange, children }) => {
  const [selected, setSelected] = useState<any>(initialSelection || items[0]);

  const handleSelectionChange = (selectedItem: any) => {
    setSelected(selectedItem);
    onSelectionChange(selectedItem);
  };

  return (
    <HeadlessListbox value={selected} onChange={handleSelectionChange}>
      {children}
    </HeadlessListbox>
  );
};

const Button: FC<ButtonProps> = ({ children }) => (
  <HeadlessListbox.Button className=" rounded-full hover:bg-slate-300">
    {children}
  </HeadlessListbox.Button>
);

const Options: FC<ListboxOptionsProps> = ({ children }) => (
  <Transition
    as={Fragment}
    leave="transition ease-in duration-100"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <HeadlessListbox.Options className="absolute z-5 mt-1 max-h-80 w-30 right-3 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
      {children}
    </HeadlessListbox.Options>
  </Transition>
);

const Option: FC<ListboxOptionProps> = ({ value, children }) => (
  <HeadlessListbox.Option
    className={({ active }) =>
      `relative flex cursor-pointer select-none ${
        active ? "bg-primary-100 text-primary-900" : "text-gray-900"
      }`
    }
    value={value}
  >
    {children}
  </HeadlessListbox.Option>
);

Listbox.Button = Button;
Listbox.Options = Options;
Listbox.Option = Option;

export default Listbox;
