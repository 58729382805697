import { Textarea as BaseTextarea, TextareaProps } from "baseui/textarea";

const Textarea: React.FC<TextareaProps> = (props) => {
  return (
    <BaseTextarea
      {...props}
      overrides={{
        Input: {
          style: { backgroundColor: "rgb(249 250 251)" },
        },
        ...props.overrides,
      }}
    />
  );
};

export default Textarea;
