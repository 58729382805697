import { Select as BaseSelect, SelectProps } from "baseui/select";

type SelectPropsWithOverride = SelectProps & { overrides?: any };

const Select: React.FC<SelectPropsWithOverride> = ({
  overrides = {},
  ...props
}) => {
  return (
    <BaseSelect
      {...props}
      overrides={{
        ...overrides,
        ControlContainer: {
          style: { backgroundColor: "rgb(249 250 251)" },
        },
      }}
    />
  );
};

export default Select;
