import { ReactNode } from "react";
import { StateCreator } from "zustand";

export interface ToastType {
  message: string;
  icon?: ReactNode | null;
  type: "success" | "error" | "info" | "warning" | null;
  position?: "top" | "bottom" | "bottom-right" | null;
  action?: {
    label: string;
    href: string;
  };
  secondaryText?: string;
}

export interface ToastSlice {
  toast: ToastType | null;
  showToast: (toast: ToastType | null, duration?: number) => void;
}

export const createToastSlice: StateCreator<ToastSlice> = (set) => {
  let toastTimeout: NodeJS.Timeout;

  return {
    toast: null,
    showToast: (toast, duration = 3000) => {
      if (toastTimeout) {
        clearTimeout(toastTimeout);
      }

      set({ toast });
      toastTimeout = setTimeout(() => set({ toast: null }), duration);
    },
  };
};
