import Link from "next/link";
import type {
  ComponentProps,
  ForwardRefRenderFunction,
  PropsWithChildren,
  ReactNode,
} from "react";
import classnames from "classnames";
import { Tooltip } from "@src/common/components";
import { NotificationCircle, COLOR } from "baseui/badge";
import React from "react";

export interface SidebarItemProps
  extends PropsWithChildren,
    Omit<ComponentProps<"div">, "text"> {
  active?: boolean;
  disabled?: boolean;
  tooltipContent?: string | undefined;
  icon?: any;
  label?: ReactNode;
  href?: string;
  badgeContent?: number;
}

const SidebarItem: ForwardRefRenderFunction<HTMLDivElement, SidebarItemProps> =
  (
    {
      label,
      icon,
      href,
      active = true,
      disabled = false,
      tooltipContent = undefined,
      onClick,
      badgeContent,
    },
    ref
  ) => (
    <Link href={href ? href : ""}>
      <div
        ref={ref}
        className={classnames({
          "rounded-xl flex flex-col p-1 justify-around items-center mx-1": true,
          "cursor-not-allowed": disabled,
          "cursor-pointer": !disabled,
          "bg-primary-200": active && !disabled,
          "hover:bg-slate-200": !disabled && !active,
          "bg-slate-100": disabled,
        })}
        onClick={!disabled ? onClick : () => {}}
      >
        {badgeContent ? (
          <NotificationCircle content={badgeContent} color={COLOR.negative}>
            {tooltipContent ? (
              <Tooltip content={tooltipContent}>{icon || null}</Tooltip>
            ) : (
              icon || null
            )}
          </NotificationCircle>
        ) : (
          <div>
            {tooltipContent ? (
              <Tooltip content={tooltipContent}>{icon || null}</Tooltip>
            ) : (
              icon || null
            )}
          </div>
        )}
        {label && (
          <span className="text-xs text-center text-slate-600">{label}</span>
        )}
      </div>
    </Link>
  );

SidebarItem.displayName = "SidebarItem";

export default React.forwardRef(SidebarItem);
