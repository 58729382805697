import {
  Modal as OriginalModal,
  ModalBody as OriginalModalBody,
  ModalFooter as OriginalModalFooter,
  ModalHeader as OriginalModalHeader,
} from "baseui/modal";

export const BaseModal = OriginalModal;
export const BaseModalFooter = OriginalModalFooter;

export const BaseModalHeader = ({ style, ...props }: any) => (
  <>
    <OriginalModalHeader
      style={{ fontWeight: 500, fontSize: "1.25rem", ...style }}
      {...props}
    />
    <hr />
  </>
);

export const BaseModalBody = OriginalModalBody;
