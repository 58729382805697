import { FC } from "react";
import Image, { ImageProps } from "next/image";
import { Avatar } from "baseui/avatar";
import classNames from "classnames";

interface DriverAvatarProps extends Partial<ImageProps> {
  driverPhoto?: string | null | undefined;
  driverName?: string;
  size?: number;
  layout?: "fixed" | "fill" | "intrinsic" | "responsive" | undefined;
  className?: string;
  isActive?: boolean;
}

const DriverAvatar: FC<DriverAvatarProps> = (props: DriverAvatarProps) => {
  const {
    driverPhoto,
    driverName,
    size,
    layout,
    className,
    onClick,
    isActive = false,
  } = props;
  const classes = `${classNames({
    "rounded-full flex": true,
    "w-8 h-8": !size,
  })} ${className}`;

  if (driverPhoto) {
    return (
      <div className={classes} onClick={onClick}>
        <Image
          src={driverPhoto}
          alt="Driver photo"
          width={size || 32}
          height={size || 32}
          objectFit="cover"
          layout={layout}
          className="rounded-full"
        />
      </div>
    );
  }

  if (!driverPhoto && driverName) {
    return (
      <div className={classes} onClick={onClick}>
        <Avatar name={driverName} size={size ? `${size}px` : "100%"} />
      </div>
    );
  }

  return null;
};

export { DriverAvatar };
