import { useGetEndCustomersOverviewLazyQuery } from "@api/graphql/generated/generated-types";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";

// Define the shape of the context state
interface LayoutContextState {
  badgeCounts: { [key: string]: number };
  setBadgeCounts: React.Dispatch<
    React.SetStateAction<{ [key: string]: number }>
  >;
  refetchEndCustomersLength: () => void;
}

// Create the context
const LayoutContext = createContext<LayoutContextState | undefined>(undefined);

// Create the provider component
export const LayoutProvider = ({ children }: { children: ReactNode }) => {
  const { courierId } = useContext(AuthContext);
  const { showToast } = useCourieStore();
  const [badgeCounts, setBadgeCounts] = useState<{ [key: string]: number }>({});

  // graphql
  const [
    getEndCustomersOverview,
    { error: getEndCustomersLengthError, refetch: refetchEndCustomersLength },
  ] = useGetEndCustomersOverviewLazyQuery({
    pollInterval: 600000,
    onCompleted: (data) => {
      if (data) {
        setBadgeCounts({
          customers:
            data.endCustomersOverview.numUnconfirmedCustomerPortalEndCustomers,
        });
      }
    },
  });

  useEffect(() => {
    if (getEndCustomersLengthError) {
      showErrorToast(getEndCustomersLengthError, showToast);
    }
  }, [getEndCustomersLengthError]);

  useEffect(() => {
    if (!courierId) {
      return;
    }
    getEndCustomersOverview({
      variables: {
        courierId,
      },
    }).then((res) => {
      if (res.data) {
        setBadgeCounts({
          customers:
            res.data.endCustomersOverview
              .numUnconfirmedCustomerPortalEndCustomers,
        });
      }
    });
  }, [getEndCustomersOverview]);

  return (
    <LayoutContext.Provider
      value={{ badgeCounts, setBadgeCounts, refetchEndCustomersLength }}
    >
      {children}
    </LayoutContext.Provider>
  );
};

// Create a custom hook to use the LayoutContext
export const useLayout = () => {
  const context = useContext(LayoutContext);
  if (context === undefined) {
    throw new Error("useLayout must be used within a LayoutProvider");
  }
  return context;
};
