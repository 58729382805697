// Accordion.tsx

import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import React, { useState } from "react";

interface AccordionProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  className?: string;
  isExpanded?: boolean;
}

const Accordion: React.FC<AccordionProps> = ({
  children,
  title,
  className,
  isExpanded,
}) => {
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(
    isExpanded || false
  );
  const index = 1;
  return (
    <div id="accordion-collapse" data-accordion="collapse">
      <React.Fragment key={index}>
        <h2 id={`accordion-collapse-heading-${index + 1}`}>
          <button
            type="button"
            className={classNames({
              "flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800":
                true,
              "bg-gray-100 dark:bg-gray-800": !isAccordionExpanded,
            })}
            onClick={() => setIsAccordionExpanded(!isAccordionExpanded)}
            aria-expanded={isAccordionExpanded}
            aria-controls={`accordion-collapse-body-${index + 1}`}
          >
            <span className="flex">
              {title} {isAccordionExpanded ? "" : "..."}
            </span>
            {isAccordionExpanded ? (
              <ChevronUpIcon className="w-5 h-5" />
            ) : (
              <ChevronDownIcon className="w-5 h-5" />
            )}
          </button>
        </h2>
        <div
          id={`accordion-collapse-body-${index + 1}`}
          className={isAccordionExpanded ? "block" : "hidden"}
          aria-labelledby={`accordion-collapse-heading-${index + 1}`}
        >
          <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
            {children}
          </div>
        </div>
      </React.Fragment>
    </div>
  );
};

export default Accordion;
