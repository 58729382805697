import { Spinner } from "flowbite-react";
import React, { CSSProperties, useEffect, useState } from "react";

type LoadingProps = {
  loading: boolean;
  fullscreen?: boolean;
  text?: string;
  image?: React.ReactNode | undefined;
  children?: React.ReactNode;
  className?: string;
  style?: CSSProperties;
};

const Loading: React.FC<LoadingProps> = ({
  loading,
  fullscreen,
  text,
  image,
  children,
  className,
  style: styleProp,
}) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);
  const style = (): CSSProperties => {
    if (fullscreen) {
      disableScroll();

      return {
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 100,
        ...styleProp,
      };
    } else {
      enableScroll();

      if (loading) {
        return {
          position: "relative",
        };
      }
      return {};
    }
  };

  const documentBody = () => {
    if (isMounted) {
      return document.body;
    }
  };

  const disableScroll = () => {
    if (isMounted) {
      const body = documentBody();
      if (body) {
        body.style.setProperty("overflow", "hidden");
      }
    }
  };

  const enableScroll = () => {
    if (isMounted) {
      const body = documentBody();
      if (body) {
        body.style.removeProperty("overflow");
      }
    }
  };

  return (
    <div style={style()} className={className}>
      {loading && (
        <div
          className="absolute top-0 right-0 bottom-0 left-0 bg-white bg-opacity-90 z-50 flex items-center justify-center"
          style={{ backdropFilter: "blur(2px)" }}
        >
          <div className="flex flex-col gap-2 items-center">
            {image && <div>{image}</div>}
            <div className="flex items-center space-x-2">
              <Spinner size="lg" />
              {text && <p className="ml-2 text-gray-600">{text}</p>}
            </div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

Loading.defaultProps = {
  loading: true,
};

export { Loading };
export type { LoadingProps };
