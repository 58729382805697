import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import React from "react";
import { PageInfo } from "@api/graphql/generated/generated-types";
import { Button } from "flowbite-react";

type Props = {
  currentPage: number;
  pageInfo: PageInfo;
  onPageChange: (page: number) => void;
  previousLabel?: string;
  nextLabel?: string;
  className?: string;
};

export const Paginator = function ({
  currentPage,
  pageInfo,
  onPageChange,
  previousLabel,
  nextLabel,
  className = "rounded-b-lg sticky right-0 bottom-0 items-center p-4 dark:bg-gray-800 sm:flex sm:justify-between",
}: Props) {
  const handlePageChange = (page: number) => {
    onPageChange(page);
  };
  let startCursor, endCursor;
  if (pageInfo.startCursor && pageInfo.endCursor) {
    startCursor = parseInt(pageInfo.startCursor) + 1;
    endCursor = parseInt(pageInfo.endCursor) + 1;
  }

  return (
    <div className={className}>
      <div className="mb-4 flex items-center sm:mb-0">
        <div className="border flex rounded-md mr-2 m-2">
          <div className="border-r">
            <Button
              size={"xs"}
              color={"secondary"}
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 0}
            >
              <ChevronLeftIcon className="h-3 w-3 mr-1" />
              <span className="text-sm">{previousLabel}</span>
            </Button>
          </div>
          <div>
            <Button
              size={"xs"}
              color={"secondary"}
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={!pageInfo.hasNextPage}
            >
              <span className="text-sm">{nextLabel}</span>
              <ChevronRightIcon className="h-3 w-3 ml-1" />
            </Button>
          </div>
        </div>
        <span className="text-sm font-normal text-gray-500 dark:text-gray-400">
          <span className="text-sm font-semibold text-gray-900 dark:text-white">
            {startCursor}-{endCursor}
          </span>
        </span>
      </div>
    </div>
  );
};
