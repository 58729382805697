import { ImageProps } from "next/image";
import Image from "next/image";
import { ReactNode } from "react";

export interface Props extends ImageProps {
  caption?: ReactNode | undefined;
  overlayItem?: ReactNode | undefined;
}

function ImageWithCaption({ caption, overlayItem, ...props }: Props) {
  return (
    <div className="flex flex-col">
      <div className="flex-init relative">
        <Image {...props} alt="" />
        {overlayItem && (
          <div className="absolute top-2 right-2">{overlayItem}</div>
        )}
      </div>
      {caption && (
        <div className="flex-1 text-slate-500 text-2xl text-center italic">
          {caption}
        </div>
      )}
    </div>
  );
}

export default ImageWithCaption;
