import Link from "next/link";
import React from "react";
import SidebarItem from "@src/common/components/Sidebar/SidebarItem";
import classnames from "classnames";

type Props = {
  children: React.ReactNode;
  className?: any;
};

function SidebarComponent({ children, className }: Props) {
  return (
    <div
      className={classnames({
        "flex flex-col gap-4 items-center pt-3": true,
        [className]: className,
      })}
    >
      {children}
    </div>
  );
}

export const Sidebar = { Group: SidebarComponent, Item: SidebarItem };
