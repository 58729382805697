import { CheckCircleIcon, MapPinIcon } from "@heroicons/react/24/outline";
import React from "react";

type StatusDisplayProps = {
  value: number;
};

const StatusDisplay: React.FC<StatusDisplayProps> = ({ value }) => {
  const renderIcon = () => {
    if (value === 0) {
      return <CheckCircleIcon className="h-3.5 w-3.5" />;
    } else {
      return <MapPinIcon className="h-3.5 w-3.5" />;
    }
  };

  return (
    <div className="flex items-center">
      {renderIcon()}
      {value === 0 ? (
        <span className="text-xs ml-0.5">Free</span>
      ) : (
        <span className="text-xs ml-0.5">{value}</span>
      )}
    </div>
  );
};

export default StatusDisplay;
