import Image from "next/image";
import React from "react";
import Checklist from "./Checklist.svg";

type Props = {
  children?: React.ReactNode;
};

function ZeroList({ children }: Props) {
  return (
    <div className="h-full p-4 flex flex-col items-center ">
      <Image src={Checklist} alt="Checklist" />
      {children}
    </div>
  );
}

export default ZeroList;
