import { Toast } from "@src/common/components";
import {
  CheckIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { ToastType } from "@src/common/lib/slices/toastSlice";
import { ExclamationTriangleIcon, FireIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import Link from "next/link";

export function LayoutToast({ toast }: { toast: ToastType }) {
  function getIcon() {
    if (toast.type === "success") {
      return <CheckIcon className="h-5 w-5" />;
    }
    if (toast.type === "error") {
      return <ExclamationCircleIcon className="h-5 w-5" />;
    }
    if (toast.type === "warning") {
      return <ExclamationTriangleIcon className="h-5 w-5" />;
    }
    if (toast.type === "info") {
      return <InformationCircleIcon className="h-5 w-5" />;
    }
    return <FireIcon className="h-5 w-5" />;
  }
  return (
    <Toast
      style={{ zIndex: 400 }}
      className={classNames({
        "fixed left-1/2 -translate-x-1/2 z-50 shadow-lg":
          !toast.position ||
          toast.position === "top" ||
          toast.position === "bottom",
        "fixed right-4 z-50 shadow-lg": toast.position === "bottom-right",
        "top-4": !toast.position || toast.position === "top",
        "bottom-4":
          toast.position === "bottom" || toast.position === "bottom-right",
        "!bg-green-300 dark:bg-green-800 text-green-800":
          toast.type === "success",
        "!bg-red-300 dark:bg-red-800 text-red-800": toast.type === "error",
        "!bg-orange-300 dark:bg-orange-700 text-orange-800":
          toast.type === "warning",
        "!bg-slate-100 dark:bg-slate-800 text-slate-800 shadow-2xl":
          toast.type === "info" || toast.type === null,
      })}
    >
      <div
        className={classNames({
          "inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg":
            true,
          "bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200":
            toast.type === "success",
          "bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200":
            toast.type === "error",
          "bg-orange-100 text-orange-500 dark:bg-orange-700 dark:text-orange-200":
            toast.type === "warning",
          "bg-blue-100 text-blue-500 dark:bg-blue-800 dark:text-blue-200":
            toast.type === "info" || toast.type === null,
        })}
      >
        {getIcon()}
      </div>
      <div className="ml-3 text-sm font-normal">
        <div className="text-sm font-normal">{toast.message}</div>
        {toast.secondaryText && (
          <div dangerouslySetInnerHTML={{ __html: toast.secondaryText }} />
        )}
        {toast.action && (
          <Link href={toast.action.href}>
            <span className="mt-0.5 inline-block font-medium text-blue-600 hover:underline dark:text-blue-400 hover:cursor-pointer">
              {toast.action.label}
            </span>
          </Link>
        )}
      </div>
      <Toast.Toggle />
    </Toast>
  );
}
