import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/outline";
import { Button } from "@src/common/components";
import { incrementDays, isSameDay, isToday } from "@src/common/lib/DateUtils";
import { useShipmentFiltersContext } from "@src/shipments/context/ShipmentFiltersContext";
import { useTableControl } from "@src/shipments/context/TableControlContext";
import { DatePicker } from "baseui/datepicker";

type Props = {
  minDate: Date | undefined;
  setMinDate: (date: Date) => void;
  maxDate: Date | undefined;
  setMaxDate: (date: Date) => void;
};

export const DateBasedPaginator = function ({
  minDate,
  setMinDate,
  maxDate,
  setMaxDate,
}: Props) {
  const sameDay = isSameDay(minDate, maxDate);
  const { setFilters } = useTableControl();

  const isTodaySelected = sameDay && minDate && isToday(minDate);
  const { setSearchTerm, setDisplayIdsFilter, setTrackingNumbersFilter } =
    useShipmentFiltersContext();
  return (
    <div className="flex items-end gap-1">
      <div className="flex items-center">
        {!isTodaySelected && (
          <div>
            <Button
              size={"xs"}
              color={"secondary"}
              onClick={() => {
                setMinDate(new Date());
                setMaxDate(new Date());
              }}
              className={"hover:bg-slate-100"}
            >
              <div className="flex gap-1 items-center truncate">
                <CalendarIcon className="h-4 w-4" /> <span>Go to Today</span>
              </div>
            </Button>
          </div>
        )}
        <div className="flex flex-1">
          <Button
            size={"xs"}
            color={"secondary"}
            onClick={() => {
              if (!minDate || !maxDate) {
                return;
              }
              setMinDate(incrementDays(minDate, -1));
              setMaxDate(incrementDays(maxDate, -1));
            }}
            className="hover:bg-slate-100"
          >
            <ChevronLeftIcon className="h-5 w-5" />
          </Button>
          <Button
            size={"xs"}
            color={"secondary"}
            onClick={() => {
              if (!minDate || !maxDate) {
                return;
              }
              setMinDate(incrementDays(minDate, 1));
              setMaxDate(incrementDays(maxDate, 1));
            }}
            className="hover:bg-slate-100"
          >
            <ChevronRightIcon className="h-5 w-5" />
          </Button>
        </div>
      </div>
      <div className="flex-init">
        <DatePicker
          range={true}
          value={sameDay ? [minDate, minDate] : [minDate, maxDate]}
          formatString={"MM/dd/yy"}
          size="mini"
          onChange={({ date }) => {
            setMinDate(date[0]);
            setMaxDate(date[1]);
          }}
          quickSelect
          overrides={{
            Input: {
              props: {
                overrides: {
                  Input: {
                    style: {
                      cursor: "pointer",
                      backgroundColor: "rgb(255 255 255)",
                      borderBottomRightRadius: "6px",
                      ":hover": {
                        backgroundColor: "rgb(249 250 251)",
                      },
                      ":focus": {
                        outline: "none",
                        boxShadow: "none",
                        backgroundColor: "rgb(249 250 251)",
                      },
                    },
                  },
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};
