import { Input as BaseInput, InputProps } from "baseui/input";

const Input: React.FC<InputProps> = (props) => {
  return (
    <BaseInput
      {...props}
      overrides={{
        Input: {
          style: {
            backgroundColor: "rgb(249 250 251)",
          },
        },
        ...props.overrides,
      }}
    />
  );
};

export default Input;
