/* eslint-disable @next/next/no-img-element */
import React, { useContext } from "react";
import NavItem from "./NavItem";
import Image from "next/image";
import Link from "next/link";
import UserButton from "@src/auth/components/UserButton";

type Props = {
  children: React.ReactNode;
};

function NavComponent({ children }: Props) {
  return (
    <nav className="relative flex w-full items-center">
      <div className="navbar-left-content">
        <Link href="/deliveries/orders">
          <a className="ml-2 flex items-center p-2 rounded-full hover:bg-slate-200 transition ease-in-out delay-75">
            <Image src={"/courielogo.png"} alt="Logo" width={30} height={30} />
          </a>
        </Link>
      </div>
      <div className="absolute left-1/2 transform -translate-x-1/2 flex">
        {children}
      </div>
      <div className="flex items-center h-full ml-auto">
        <UserButton />
      </div>
    </nav>
  );
}

export const Nav = { Group: NavComponent, Item: NavItem };
