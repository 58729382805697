import { ApolloError } from "@apollo/client";

export function showErrorToast(error: ApolloError, showToast: any) {
  if (
    error?.graphQLErrors &&
    error?.graphQLErrors.length > 0 &&
    error.graphQLErrors[0].extensions.errorType === "BAD_REQUEST"
  ) {
    showToast({
      message: error?.graphQLErrors[0].message,
      type: "error",
    });
  } else if (error?.graphQLErrors) {
    showToast({
      message: "Something went wrong",
      type: "error",
    });
  }
}
