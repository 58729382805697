import React from "react";

type Props = {
  children?: React.ReactNode;
  className?: string;
};

export default function TableContainer({ children, className }: Props) {
  return (
    <div className={`border bg-white rounded-t-3xl ${className}`}>
      {children}
    </div>
  );
}
