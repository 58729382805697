import { Combobox as BaseCombobox, ComboboxProps } from "baseui/combobox";
import { useEffect, useState } from "react";

const Combobox: React.FC<ComboboxProps> = (props) => {
  const [overrides, setOverrides] = useState({});

  const deepMerge = (target: any, source: any) => {
    const isObject = (obj: {}) => obj && typeof obj === "object";

    if (!isObject(target) || !isObject(source)) {
      return source;
    }

    Object.keys(source).forEach((key) => {
      const targetValue = target[key];
      const sourceValue = source[key];

      if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
        target[key] = targetValue.concat(sourceValue);
      } else if (isObject(targetValue) && isObject(sourceValue)) {
        target[key] = deepMerge(Object.assign({}, targetValue), sourceValue);
      } else {
        target[key] = sourceValue;
      }
    });

    return target;
  };

  useEffect(() => {
    const x = {
      Input: {
        props: {
          overrides: {
            Input: {
              style: {
                backgroundColor: "rgb(249 250 251)",
              },
            },
          },
        },
      },
    };

    const mergedOverrides = deepMerge(x, props.overrides);
    setOverrides(mergedOverrides);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.overrides]);

  return <BaseCombobox {...props} overrides={overrides} />;
};

export default Combobox;
