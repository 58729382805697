import React from "react";
import { RadioProps } from "flowbite-react";
import { Radio } from "@src/common/components/Radio";
import { Label } from "@src/common/components/Label";

interface RadioWithLabelProps extends RadioProps {
  children: React.ReactNode;
  htmlFor: string;
}

export default function RadioWithLabel({
  children,
  htmlFor,
  ...props
}: RadioWithLabelProps) {
  return (
    <div className="flex items-center gap-2 cursor-pointer">
      <div className="flex-init cursor-pointer">
        <Radio id={htmlFor} className="cursor-pointer" {...props} />
      </div>
      <div className="flex-1 cursor-pointer">
        <Label className="cursor-pointer" htmlFor={htmlFor}>
          {children}
        </Label>
      </div>
    </div>
  );
}
