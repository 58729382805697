import React, { forwardRef, ReactNode } from "react";
import {
  Button as FlowbiteButton,
  ButtonProps,
  CustomFlowbiteTheme,
} from "flowbite-react";

interface CourieButtonProps extends ButtonProps {
  children: ReactNode;
}

const customTheme: CustomFlowbiteTheme["button"] = {
  color: {
    yellow: "bg-yellow-200 hover:bg-yellow-300",
    primary: "bg-primary-400 hover:bg-primary-500",
  },
};

const CourieButton = forwardRef<HTMLButtonElement, CourieButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <FlowbiteButton ref={ref} theme={customTheme} pill={true} {...props}>
        {children}
      </FlowbiteButton>
    );
  }
);

CourieButton.displayName = "CourieButton";

export default CourieButton;
