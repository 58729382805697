import {
  DashboardWidget,
  WidgetName,
  WidgetState,
} from "@src/common/components/Widgets/dashboardWidgetTypes";
import { ReactNode } from "react";
import { StateCreator } from "zustand";

export type WidgetNames = WidgetName | null;

export type DisabledWidgetItem = {
  widgetItem: WidgetNames;
  tooltipContent: string | undefined;
};

export interface WidgetsSlice {
  widgets: DashboardWidget[];
  setWidgets: (widgets: DashboardWidget[]) => void;
  selectedWidget: WidgetNames;
  setSelectedWidget: (widgetName: WidgetNames) => void;
  selectedWidgetItem: any;
  setSelectedWidgetItem: (widgetItem: any) => void;
  disabledWidgetItems: DisabledWidgetItem[];
  setDisabledWidgetItems: (widgetItems: DisabledWidgetItem[]) => void;
  closeWidgets: () => void;
  openWidget: (
    name: WidgetNames,
    newState?: WidgetState,
    customComponent?: ReactNode
  ) => void;
}

const initialState: {} = {
  widgets: [],
  selectedWidget: null,
  selectedWidgetItem: null,
  disabledWidgetItems: [],
};

export const createWidgetsSlice: StateCreator<WidgetsSlice> = (set) => ({
  widgets: [],
  setWidgets: (widgets: DashboardWidget[]) => {
    set({ ...initialState, widgets });
  },
  selectedWidget: WidgetName.DISPATCH_CANDIDATES,
  setSelectedWidget: (widgetName: WidgetNames) => {
    set({ selectedWidget: widgetName });
  },
  selectedWidgetItem: null,
  setSelectedWidgetItem: (widgetItem: DashboardWidget) => {
    set({ selectedWidgetItem: widgetItem });
  },
  disabledWidgetItems: [],
  setDisabledWidgetItems: (widgetItems: DisabledWidgetItem[]) => {
    set({ disabledWidgetItems: widgetItems });
  },
  closeWidgets: () => {
    set((state) => ({
      selectedWidget: null,
      widgets: state.widgets.map((widget) => ({
        ...widget,
        selected: false,
      })),
    }));
  },
  openWidget: (
    name: WidgetNames,
    newState?: WidgetState,
    customComponent?: ReactNode
  ) => {
    set((state) => ({
      selectedWidget: name,
      widgets: state.widgets.map((widget) => ({
        ...widget,
        selected: widget.name === name,
        customComponent: customComponent || null,
        state: widget.name === name ? newState || widget.state : widget.state,
      })),
    }));
  },
});
