/* eslint-disable react-hooks/exhaustive-deps */
import "../styles/globals.css";
import type { AppProps } from "next/app";
import { ApolloProvider } from "@apollo/client";
import Layout from "@common/components/Layout/Layout";
import { Provider as StyletronProvider } from "styletron-react";
import { LightTheme, BaseProvider } from "baseui";
import { styletron } from "@src/common/lib/styletron";
import "firebase/auth";
import { AuthContext, AuthProvider } from "@src/auth/components/AuthProvider";
import apolloClient from "@src/common/lib/apollo/ApolloClients";
import Script from "next/script";
import { ErrorBoundary } from "@sentry/react";
import { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import commonRoutes from "@src/common/lib/routing/commonRoutes";
import { LayoutProvider } from "@src/common/components/Layout/contexts/LayoutContext";

console.warn = () => {};

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <StyletronProvider value={styletron}>
          <BaseProvider theme={LightTheme}>
            <AuthProvider>
              <Index>
                <Component {...pageProps} />
              </Index>
            </AuthProvider>
          </BaseProvider>
        </StyletronProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

function Index({ children }) {
  const { courierId, user, authLoading, isNewUser } = useContext(AuthContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const router = useRouter();
  useEffect(() => {
    if (!authLoading && courierId && user && !isAuthenticated) {
      setIsAuthenticated(true);
    }
    if (!authLoading && !user && !isNewUser) {
      router.push(commonRoutes.login);
      setIsAuthenticated(false);
      return;
    }
    if (isAuthenticated && !courierId) {
      setIsAuthenticated(false);
    }
  }, [courierId, user, authLoading]);

  const googleMapsUrl = `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAP_ACCESS_TOKEN}&libraries=places`;
  if (authLoading) {
    return <p>Loading</p>;
  }

  return isAuthenticated ? (
    <AuthenticatedApp googleMapsUrl={googleMapsUrl}>
      {children}
    </AuthenticatedApp>
  ) : (
    <UnAuthenticatedApp>{children}</UnAuthenticatedApp>
  );
}

function AuthenticatedApp({ children, googleMapsUrl }) {
  return (
    <LayoutProvider>
      <Layout>
        <Script src={googleMapsUrl} />
        {children}
      </Layout>
    </LayoutProvider>
  );
}

function UnAuthenticatedApp({ children }) {
  return <>{children}</>;
}

export default MyApp;
