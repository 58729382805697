import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import "firebase/auth";
import { Auth, getAuth } from "firebase/auth";
import firebaseApp from "@src/auth/lib/firebaseClient";

// Set up an authentication link to inject the ID token into the request headers
const authLink = setContext(async (_, { headers }) => {
  const auth: Auth = getAuth(firebaseApp);
  // Get the current user's ID token
  const token = auth.currentUser ? await auth.currentUser.getIdToken() : null;

  // Return the new headers with the ID token added
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const uploadLink = createUploadLink({
  uri: process.env.NEXT_PUBLIC_GRAPHQL_SCHEMA_URL,
});

const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, uploadLink]),
  cache: new InMemoryCache(),
});

export default apolloClient;
