import { create } from "zustand";
import { createWidgetsSlice, WidgetsSlice } from "./slices/widgetsSlice";
import { createToastSlice, ToastSlice } from "./slices/toastSlice";
import { devtools } from "zustand/middleware";

type AppState = WidgetsSlice & ToastSlice;

export const useCourieStore = create<AppState>()(
  devtools((...a) => ({
    ...createWidgetsSlice(...a),
    ...createToastSlice(...a),
  }))
);
